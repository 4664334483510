<template>
  <div class="page-person-info">
    <div class="content">
      <div class="row-icon">
<!--        <i :class="['el-icon-edit', 'edit-btn']" v-if="!isEdit" @click="isEdit = true">编辑</i>-->
        <i :class="['el-icon-check', 'edit-btn']" @click="submitForm('ruleForm')">保存</i>
      </div>
      <el-form ref="form" :model="form" label-width="80px" v-loading="loading">
        <el-row>
          <el-col :span="8">
            <el-form-item label="公司名称">
              <el-input v-model="form.companyName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人">
              <el-input v-model="form.contact"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话">
              <el-input v-model="form.contactNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!--<el-form-item label="性别">
          <el-select v-model="form.region" placeholder="请选择性别">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </el-form-item>-->
      </el-form>
    </div>
    <!--
    <div class="template">
      <el-row :gutter="20">
        <el-col :span="6" v-for="(item, n) in templateList" :key="n">
          <div class="temp-item images">
            <div class="copy-box" v-viewer="{movable: false}">
              <img :src="item.cover" alt="" width="100%">
              <p class="title" :title="item.title">{{item.title}}</p>
            </div>
            <div class="model">
              <div class="tools">
                <i class="el-icon-view" @click="show">预览</i>
                <i class="el-icon-share" @click="download(item)">下载</i>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    -->
  </div>
</template>

<script>
export default {
  name: 'personInfo',
  data () {
    return {
      form: {},
      isEdit: false,
      loading: true,
      templateList: [
        {
          id: 245,
          title: '模板1',
          cover: 'http://www.xueshuibao.com/static/img//images/article/20220416/20220416143035120.png',
          itemId: 0,
          itemName: null,
          cateId: 56,
          cateName: null,
          intro: '',
          imgs: null,
          imgsList: null,
          content: null,
          status: 1,
          statusName: '正常',
          browse: 0,
          sort: 1,
          createUser: 1,
          createTime: '2022-04-16T06:31:09.000+00:00',
          updateUser: null,
          updateTime: null,
          mark: null
        }
      ]
    }
  },
  methods: {
    getInfo () {
      this.loading = false
      this.API.geBaseInfo().then(({ code, data }) => {
        this.form = data
      }).finally(() => {
        this.loading = false
      })
    },
    submitForm () {
      this.API.editBaseInfo(this.form).then(({ code, data }) => {
        if (code === 200) {
          this.$message.success('个人信息更新成功')
          // this.isEdit = false
        }
      })
    },
    getTemplate () {
      this.API.getShareTemplate().then(({ code, data }) => {
        this.templateList = data
      })
    },
    show () {
      const viewer = this.$el.querySelector('.copy-box').$viewer
      viewer.show()
    },
    download (item) {
      this.downLoadUrl(item.cover)
    }
  },
  created () {
    this.getInfo()
    this.getTemplate()
  }
}
</script>

<style scoped lang="scss">
.page-person-info {
  .content {
    margin: 30px 20px;
    text-align: left;
    .row-icon {
      height: 40px;
      line-height: 40px;
      color: #409EFF;
      .edit-btn {
        float: right;
        cursor: pointer;
      }
    }

  }

  .template {
    margin: 30px;

    .temp-item {
      height: 300px;
      border: 1px solid #eee;
      position: relative;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      &:hover{
        .model{
          display: block;
        }
      }
      .copy-box {
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
        .title{
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .model {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
        display: none;
        .tools {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: #fff;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          i {
            margin: 10px 0;
            cursor: pointer;
            &:hover {
              color: #409EFF;
            }
          }
        }
      }
    }
  }
}
</style>
